<template id="staffPicker">
    <div class="frodo">
        <div class="col-12">
            <h2>{{ customTitle ? customTitle : __("mother.staff") }}</h2>
        </div>
        <div class="col-6">
            <input
                type="text"
                v-model="searchStaffInput"
                ref="searchStaffInput"
                @keyup="searchStaffInputKey($event)"
                autocomplete="off"
                :placeholder="__('mother.search') + '...'"
            />
        </div>
        <!-- Departments -->
        <div class="col-6">
            <label class="selectLabel">
                <select v-model="searchStaffDepartment" @change="searchStaff()">
                    <option value="_DEPARTMENTS">{{ __("mother.departments") }}</option>
                    <option value="_ALL">{{ __("mother.all") }}</option>
                    <option v-for="department in departments" :value="department.id">{{ department.name }}</option>
                </select>
                <i class="fal fa-caret-down"></i>
            </label>
        </div>
        <!-- /Departments -->
        <div class="col-12"></div>

        <div class="col-6">
            <a v-if="staffDisplay.length > 0" @click="selectAll" class="button-small button-blue" style="margin-bottom: 5px"
                ><i class="fal fa-check"></i> {{ __("mother.selectAll") }}</a
            >
            <a v-else class="button-small button-disabled" disabled style="margin-bottom: 5px"><i class="fal fa-check"></i> {{ __("mother.selectAll") }}</a>
            <div class="staffTableWrapper" id="staffTableWrapper" v-bind:class="{ staffTableWrapperSafari: browser == 'Safari' && staffDisplay.length < 6 }">
                <table class="staffTable" id="staffTable">
                    <tr v-for="(staff, index) in staffDisplay" v-if="!staff.hide_in_staff">
                        <td :tabindex="index" :ref="'staffTd' + index" class="linkRow" @click="addStaff(staff.id, staff.name)" @keyup="staffTableKey($event, staff)">
                            {{ staff.name }}
                            <span style="float: right"><i class="fal fa-plus"></i> {{ __("mother.add") }}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="col-6">
            <a @click="clearAll" class="button-small button-red button-right" style="margin-bottom: 5px"><i class="fal fa-times"></i> {{ __("mother.clear") }}</a>
            <div class="staffTableWrapper">
                <table class="staffTable" id="staffTableChosen" v-bind:class="{ staffTableWrapperSafari: browser == 'Safari' && staffArray.length < 6 }">
                    <tr v-for="(staff, index) in staffArray">
                        <td :tabindex="index" :ref="'staffTdChosen' + index" class="linkRowChosen" @click="addStaff(staff, 'NA')" @keydown="staffTableChosenKey($event, staff)">
                            {{ allStaff.find((item) => item.id == staff) ? allStaff.find((item) => item.id == staff).name : "" }}
                            <span style="float: right"><i class="fal fa-minus"></i> {{ __("mother.remove") }}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <input type="text" :name="customStaffParam ? customStaffParam : 'staffArray'" v-model="staffArrayParameter" hidden readonly />
        <input type="text" :name="customDepartmentParam ? customDepartmentParam : 'department'" v-model="searchStaffDepartment" hidden readonly />
    </div>
</template>

<script>
export default {
    name: "staffPicker",
    props: {
        old: Object | Array,
        departments: Array,
        allStaff: Array,
        staff: Array,
        showAllStaff: Boolean,
        chosenStaff: Array,
        from: Date | String,
        to: Date | String,
        customStaffParam: String,
        customDepartmentParam: String,
        customTitle: String,
    },
    data() {
        return {
            browser: _browser,
            department: 0,

            staffArray: old["staffArray"] ? JSON.parse(old["staffArray"]) : this.$props.chosenStaff ? this.$props.chosenStaff : [],
            staffArrayParameter: old["staffArray"] ? old["staffArray"] : this.$props.chosenStaff ? JSON.stringify(this.$props.chosenStaff) : "",
            staffNameArray: [],
            staffRoleSelect: [],

            searchStaffInput: "",
            searchStaffDepartment: "_DEPARTMENTS",
            staffDisplay: this.$props.showAllStaff ? this.$props.allStaff : this.$props.staff,
            displayedStaff: [],

            allSearchStaff: this.$props.showAllStaff ? this.$props.allStaff : this.$props.staff,
        };
    },
    methods: {
        selectAllOld() {
            this.staffDisplay = [];
            this.staffArray = [];
            this.staffDisplay = this.allSearchStaff;
            for (var i = 0; i < this.allSearchStaff.length; i++) {
                this.staffArray.push(this.allSearchStaff[i].id);
                this.staffNameArray[this.allSearchStaff[i].id] = this.allSearchStaff[i].name;
            }
            this.searchStaff();
            this.staffArrayParameter = JSON.stringify(this.staffArray);
            this.updateStaffArray();
        },
        selectAll() {
            this.staffArray = [];
            for (var i = 0; i < this.staffDisplay.length; i++) {
                if (!this.staffDisplay[i].hide_in_staff) {
                    this.staffArray.push(this.staffDisplay[i].id);
                    this.staffNameArray[this.staffDisplay[i].id] = this.staffDisplay[i].name;
                }
            }
            this.searchStaff();
            this.staffArrayParameter = JSON.stringify(this.staffArray);
            this.updateStaffArray();
        },
        clearAll() {
            this.staffDisplay = [];
            this.staffArray = [];
            this.staffArrayParameter = "";
            this.searchStaff();
            this.updateStaffArray();
        },

        addStaff(id, name) {
            if (this.staffArray.includes(id)) {
                var index = this.staffArray.indexOf(id);
                if (index > -1) {
                    this.staffArray.splice(index, 1);
                }
            } else {
                this.staffArray.push(id);
                this.staffNameArray[id] = name;
            }
            this.searchStaff();
            if (this.staffDisplay.length < 1) {
                this.clearSearch();
            }
            this.staffArrayParameter = JSON.stringify(this.staffArray);
            this.updateStaffArray();
        },
        updateStaffArray() {
            this.$emit("update-staff-array", this.staffArray);
        },
        searchStaffInputKey(e) {
            this.searchStaff();
            if (e.keyCode === 40) {
                this.$refs["staffTd0"][0].focus();
            }
        },
        searchStaff() {
            var search = this.searchStaffInput.toUpperCase();

            this.staffDisplay = [];

            for (var i = 0; i < this.allSearchStaff.length; i++) {
                if (this.allSearchStaff[i].name.toUpperCase().includes(search)) {
                    if (!this.staffArray.includes(this.allSearchStaff[i].id)) {
                        if (this.allSearchStaff[i].departments != null && this.searchStaffDepartment != "_ALL" && this.searchStaffDepartment != "_DEPARTMENTS") {
                            for (var dep = 0; dep < this.allSearchStaff[i].departments.length; dep++) {
                                if (this.allSearchStaff[i].departments[dep] == this.searchStaffDepartment) {
                                    this.staffDisplay.push(this.allSearchStaff[i]);
                                    break;
                                }
                            }
                        } else {
                            this.staffDisplay.push(this.allSearchStaff[i]);
                        }
                    }
                }
            }
        },
        clearSearch() {
            this.searchStaffInput = "";
            this.searchStaffDepartment = "_DEPARTMENTS";
            this.searchStaff();
            this.$refs.searchStaffInput.focus();
        },
        staffTableKey(e, staff) {
            e.preventDefault();
            if (e.keyCode === 9) {
                return false;
            }
            var tabIndex = e.srcElement.tabIndex;
            if (e.keyCode === 38) {
                if (this.staffDisplay.length > 0 && tabIndex !== 0) {
                    this.$refs["staffTd" + (tabIndex - 1)][0].focus();
                } else {
                    this.$refs.searchStaffInput.focus();
                }
            }
            if (e.keyCode === 40) {
                if (this.$refs["staffTd" + (tabIndex + 1)][0]) {
                    this.$refs["staffTd" + (tabIndex + 1)][0].focus();
                }
            }
            if (e.keyCode === 13) {
                this.addStaff(staff.id, staff.name);
                this.searchStaff();
                if (this.staffDisplay.length < 1) {
                    this.clearSearch();
                } else {
                    if (this.$refs["staffTd" + (tabIndex + 1)]) {
                        this.$refs["staffTd" + (tabIndex + 1)][0].focus();
                    } else {
                        if (this.$refs["staffTd" + (tabIndex - 1)]) {
                            this.$refs["staffTd" + (tabIndex - 1)][0].focus();
                        } else {
                            this.clearSearch();
                        }
                    }
                }
            }
            if (e.keyCode === 39) {
                if (this.$refs.staffTdChosen0) {
                    this.$refs.staffTdChosen0[0].focus();
                }
            }
            if (e.keyCode === 27 || e.keyCode === 37) {
                this.$refs.searchStaffInput.focus();
            }
        },
        staffTableChosenKey(e, staff) {
            e.preventDefault();
            if (e.keyCode === 9) {
                return false;
            }
            var tabIndex = e.srcElement.tabIndex;
            if (e.keyCode === 38) {
                if (this.staffDisplay.length > 0 && tabIndex !== 0) {
                    this.$refs["staffTdChosen" + (tabIndex - 1)][0].focus();
                }
            }
            if (e.keyCode === 40) {
                if (this.$refs["staffTdChosen" + (tabIndex + 1)][0]) {
                    this.$refs["staffTdChosen" + (tabIndex + 1)][0].focus();
                }
            }
            if (e.keyCode === 8) {
                e.preventDefault();
                this.addStaff(staff, "NA");
                this.searchStaff();

                if (this.staffArray.length < 1) {
                    this.clearSearch();
                } else {
                    if (this.$refs["staffTdChosen" + (tabIndex + 1)][0]) {
                        this.$refs["staffTdChosen" + (tabIndex + 1)][0].focus();
                    } else {
                        this.$refs["staffTdChosen" + (tabIndex - 1)][0].focus();
                    }
                }
            }
            if (e.keyCode === 37) {
                if (this.$refs.staffTd0) {
                    this.$refs.staffTd0[0].focus();
                }
            }
            if (e.keyCode === 27 || e.keyCode === 39) {
                this.$refs.searchStaffInput.focus();
            }
        },
    },
    mounted() {
        this.searchStaff();
    },
};
</script>

<style scoped>
p {
    font-size: 30px;
}
</style>
