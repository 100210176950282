<template>
	<div id="ProjectProductsMaterials">
		
		<div class="col-12">
			<h2>{{ __('wms.products') }} / {{ __('wms.materials') }}</h2>
		</div>

	</div>
</template>

<script>
	export default{
		name: 'ProjectProductsMaterials',
		props: {

		},
		data() {
			return {
				users:[],
				warehouses:[],
			}
		},
		methods: {
			dateNice(date){
				return moment(date).format('LL HH:mm');
			}
		},
	}
</script>