<template id="datePickersWithMonthSelect">
<div class="frodo">

	<div :class="colSize">
		<p>{{ __('mother.from') }}</p>
		<datetime hidden-name="from" name="fromParam" v-model="from"></datetime>
	</div>

	<div :class="colSize">
		<p>{{ __('mother.to') }}</p>
		<datetime hidden-name="to" name="toParam" v-model="to"></datetime>
	</div>

	<div v-if="showMonthSelect" class="col-6">
		<label class="selectLabel">
			<select v-model="month" @change="changeMonth">
				<option v-for="month in months" v-bind:key="month" :value="month">{{ __('mother.month'+month) }}</option>
			</select>
			<i class="fal fa-caret-down"></i>
		</label>
	</div>

	<div v-if="showGetButton" :class="buttonColSizeModel">
		<button type="button" ref="submitButton" :class="'button-medium button-blue' + buttonRight" @click="getButtonPressed()">
			<i class="fa-light fa-download"></i>
			{{ __('mother.get') }}
		</button>
		<div ref="submitStatus" class="submit-status"></div>
	</div>

</div>
</template>

<script>
	export default {
		name: 'DatePickersWithMonthSelect',
		props: {
			old: Object|Array,
			subtractDays: {
				type: Number,
				default: 0
			},
			showGetButton: {
				type: Boolean,
				default: false
			},
			getAfterLoad: {
				type: Boolean,
				default: false,
			},
			getButtonRight: {
				type: Boolean,
				default: true
			},
			showMonthSelect: {
				type: Boolean,
				default: true
			},
			colSize: {
				type: String,
				default: 'col-6'
			},
			buttonColSize: {
				type: String,
				default: 'col-12'
			},
			passedFrom: Date|String,
			passedTo: Date|String,
		},
		data(){
			return {
				browser: _browser,
				from: '',
				to: '',
				buttonRight: this.$props.getButtonRight ? ' button-right' : '',
				months: [1,2,3,4,5,6,7,8,9,10,11,12],
				month: 1,
				buttonColSizeModel: this.$props.buttonColSize,
			}
		},
		watch: {
			from(){
				this.$emit('dates-changed', [this.from, this.to]);
			},
			to(){
				this.$emit('dates-changed', [this.from, this.to]);
			}
		},
		methods: {
		    getButtonPressed(){
				this.$emit('get-button-pressed', [this.from, this.to]);
			},
			changeMonth(){
				var year = moment(this.from).format('YYYY');
				var month = this.month.length > 1 ? this.month : '0' + this.month;
				this.from = moment(year + '-' + month + '-15').startOf('month').format();
				this.to = moment(year + '-' + month + '-15').endOf('month').format();
				this.$emit('dates-changed', [this.from, this.to]);
			}
		},
		async mounted(){
			// FROM
			if(this.$props.old && this.$props.old.from){
				this.from = this.$props.old.from;
			}else if(this.$props.passedFrom) {
				this.from = moment(this.$props.passedFrom).format();
			}else{
				if(this.$props.subtractDays && this.$props.subtractDays > 0){
					this.from = moment().subtract(this.$props.subtractDays, 'days').startOf('month').format();
				}else{
					this.from = moment().startOf('month').format();
				}
			}

			// TO
			if(this.$props.old && this.$props.old.to){
				this.to = this.$props.old.to;
			}else if(this.$props.passedTo) {
				this.to = moment(this.$props.passedTo).format();
			}else{
				this.to = moment(this.from).endOf('month').format();
			}
			// MONTH
			this.month = moment(this.from).format('M');

			// ButtonColSize
			if(this.$props.showMonthSelect){
				this.buttonColSizeModel = 'col-6';
			}

			// Get after load
			await this.$nextTick();
			if(this.$props.getAfterLoad){
				this.getButtonPressed();
			}
		}
	}
</script>
