<template>
	<div id="wmsActions">
		
		<div class="col-12">
			<h2>{{ __('wms.actions') }}</h2>
		</div>

		<div class="col-12">
			<div class="tableWrapper">
				<table class="table">
					<tr>
						<td>{{ __('mother.date') }}</td>
						<th>{{ __('mother.description') }}</th>
						<th>{{ __('wms.warehouse') }}</th>
						<th>{{ __('mother.user') }}</th>
					</tr>
					<tr v-for="action in actions">
						<td>{{ dateNice(action.created_at) }}</td>
						<td>{{ __('wms.'+action.body) }}</td>
						<td>{{ warehouses.find(item => item.id == action.warehouse_id) ? warehouses.find(item => item.id == action.warehouse_id).title : 'notFound' }}</td>
						<td>{{ users.find(item => item.id == action.user_id) ? users.find(item => item.id == action.user_id).name : 'notFound' }}</td>
					</tr>
				</table>
			</div>
		</div>

	</div>
</template>

<script>
	export default{
		name: 'wmsActions',
		props: {
			actions: Array,
		},
		data() {
			return {
				users:[],
				warehouses:[],
			}
		},
		methods: {
			dateNice(date){
				return moment(date).format('LL HH:mm');
			}
		},
		mounted() {
			var vm = this;
			axios.get('/vue-helpers/get-all-models/with-trashed/User')
			.then(function(response){
				vm.users = response.data[1];
			})
			.catch(function(error){
				console.log(error)
			})
			axios.get('/vue-helpers/get-all-models/with-trashed/Warehouse')
			.then(function(response){
				vm.warehouses = response.data[1];
			})
			.catch(function(error){
				console.log(error)
			})
		},
	}
</script>