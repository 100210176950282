<template>
    <div id="SignaturePad">
        <div class="w-full h-64 border">
            <VueSignaturePad
                width="100%"
                height="100%"
                ref="signaturePad"
                :options="{
                backgroundColor: 'rgb(255,255,255)',
                onEnd
            }"
            />
        </div>
        <div class="mt-4">
            <button type="button"
                    class="bg-orange-50 hover:bg-orange-100 text-orange-600 dark:bg-orange-600 dark:text-orange-50 dark:hover:bg-orange-700 disabled:bg-gray-300 disabled:text-gray-500 dark:disabled:bg-gray-600 dark:disabled:text-gray-400 focus-visible:outline-indigo-500 font-medium rounded shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 md:text-xs px-2 py-1 text-xs"
                    @click="undo">
                <i class="fal fa-undo"></i> {{ __('mother.undo') }}
            </button>
            <button type="button"
                    class="bg-red-50 hover:bg-red-100 text-red-600 dark:bg-rose-600 dark:text-rose-50 dark:hover:bg-rose-700 disabled:bg-gray-300 disabled:text-gray-500 dark:disabled:bg-gray-600 dark:disabled:text-gray-400 focus-visible:outline-indigo-500 font-medium rounded shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 md:text-xs px-2 py-1 text-xs"
                    @click="clear">
                <i class="fal fa-times"></i> {{ __('mother.clear') }}
            </button>
        </div>
        <input type="text" name="signature" v-model="signatureParameter" hidden>
    </div>
</template>
<script>
export default {
    name: 'SignaturePad',
    props: {
        old: Object | Array
    },
    data() {
        return {
            signatureParameter: this.$props.old['signature'] ? this.$props.old['signature'] : '',
        }
    },
    methods: {
        undo() {
            this.$refs.signaturePad.undoSignature();
            this.save();
        },
        clear() {
            this.$refs.signaturePad.clearSignature();
            this.save();
        },
        save() {
            const {isEmpty, data} = this.$refs.signaturePad.saveSignature("image/jpeg");
            if (!isEmpty) {
                this.signatureParameter = data;
            } else {
                this.signatureParameter = '';
            }
        },
        onEnd() {
            this.save();
        }
    },
    mounted() {
        var vm = this;
        this.$nextTick(function () {
            if (this.$props.old['signature']) {
                console.log(this.$props.old['signature']);
                this.$refs.signaturePad.fromDataURL(this.$props.old['signature']);
            }
        })
    }
};
</script>
